import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import CreateEditItem from '@/components/CreateEditItem/CreateEditItem.vue';
import ItemList from '@/components/ItemList/ItemList.vue';
import CountDown from '@/components/CountDown/CountDown.vue';
import OptionMenu from '@/components/OptionsMenu/OptionMenu.vue';
import Modal from '@/components/Modal/Modal.vue';

import ProposalService from '@/services/API/proposal.service';
import socketClient from '@/services/SOCKET';
import TransferService from '@/services/API/transfer.service';
import ProposalGeneralService from '@/services/API/proposal_general.service';
import Mail from '@/services/API/mail.service';

import { getFileExtention, getFileName } from '@/store/modules/File';
import CommonConst from '@/constants/CommonConst';
import ProposalConst from '@/constants/ProposalConst';
import downloadPdfMixin from '../../../mixin/downloadPdfMixin';

import moment from 'moment';
import { mapState } from 'vuex';

const SAVED_NUMBER = 1;
const DEFAULT_ITEM = {
	title: undefined,
	itemId: undefined,
	commentContent: undefined,
	gridCols: undefined,
	gridStatus: undefined,
	imageUrl: undefined,
	isOpen: false,
	fileType: '',
	fileName: '',
};
export default {
	name: 'ProposalScreen',
	mixins: [downloadPdfMixin],
	components: {
		ScreenHeader,
		ProjectDeadline,
		CreateEditItem,
		ItemList,
		CountDown,
		OptionMenu,
		Modal,
	},
	data() {
		return {
			screenIcon: 'proposal-icon',
			screenName: CommonConst.PROPOSAL_SCREEN_NAME,
			selectProposal: {},
			proposalList: [],
			proposalData: {},
			isCheck: false,
			deliveryDirectLink: 'delivery',
			addcostDirectLink: 'addcost',
			middleContent: ' 様 ／ ',
			showItemList: false,
			isOpen: false,
			showFullOptionMenu: false,
			showOptionMenu: false,
			selectedHour: '',
			selectedMinute: '',
			proposalStatus: '',
			screenMode: '',
			showAnnoucement: false,
			revisionFlag: null,

			pdfPreviewer: {
				url: '',
			},
		};
	},
	computed: {
		// Get projectId. userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
		]),
	},
	methods: {
		onDownloadPdfFileFromParent(pdfUrl) { 
			this.pdfPreviewer = pdfUrl
			this.onDownloadPdfFile();
		},
		async onSelect(data) {
			const proposalList = [...this.proposalList];
			proposalList.forEach((item) => {
				if (item.itemId === data['id']) {
					if (item.isOpen) {
						item.isOpen = false;
					} else {
						item.isOpen = true;
					}
				}
			});
		},

		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: green');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
			}
		},

		async _getProposalData(projectId) {
			let response = await ProposalService.get(projectId);
			if (response.status !== 200) {
				throw 'Get proposal failed';
			}
			this.proposalData = this._filterSavedItem(response.data);
			this.proposalList = await this._filterProposalList(this.proposalData);
			if (this.proposalList.length !== 0) {
				this.showItemList = true;
			}
		},

		async _getProposalGeneralData(projectId) {
			try {
				let response = await ProposalGeneralService.get(projectId);
				if (response.status !== 200) {
					throw 'Get proposal general failed';
				}

				console.log('%c Get proposal general successfully', 'color: green');
				this.selectedHour = response.data.hours ? response.data.hours.toString() : '';
				this.selectedMinute = response.data.minutes
					? response.data.minutes.toString()
					: '';
				this.screenMode = response.data.screen_mode ? response.data.screen_mode : 0;
				this._checkScreenMode(this.screenMode);
				this.proposalStatus = response.data.status ? response.data.status : '';
				this.revisionFlag = response.data?.revision_flag;
				if (this.revisionFlag === ProposalConst.REVISION_DISABLE_NUM) {
					this.showFullOptionMenu = false;
				}

				this._checkProposalStatus(this.proposalStatus);

				this._checkNoObjectRevision();
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		async _getAllProposalData(projectId) {
			await this._getProposalData(projectId);
			await this._getProposalGeneralData(projectId);
		},

		removeQuestion(questionId) {
			this.proposalList = this.proposalList.filter(
				(proposal) => proposal.itemId !== questionId
			);
		},

		_checkNoObjectRevision() {
			if (
				this.screenMode === CommonConst.REVISION_MODE_NUMB &&
				this.proposalStatus === CommonConst.SCREEN_STATUS.REVISION
			) {
				this.showAnnoucement = false;
				this.showItemList = true;
			}
		},

		async _filterProposalList(proposalData) {
			let proposalList = [];
			proposalData.forEach(async (data) => {
				let defaultProposal = { ...DEFAULT_ITEM };
				defaultProposal['gridCols'] = 2;
				defaultProposal['gridStatus'] = 'normal';
				defaultProposal['itemId'] = data['id'];
				defaultProposal['nonfilterDateData'] = data['date_created'];
				defaultProposal['title'] = this._renameItem(
					data['title'],
					data['date_created']
				);
				/* eslint-disable no-mixed-spaces-and-tabs */
				defaultProposal['imageUrl'] = data['image_file_name']
					? await this._getPreviewImgUrl(
							data['image_file_name'],
							CommonConst.ONE_WEEK_SECONDS
					  )
					: '';
				defaultProposal['commentContent'] = data['comment'];
				defaultProposal['fileName'] = getFileName(data['image_file_name']);
				defaultProposal['fileType'] = getFileExtention(data['image_file_name']);
				proposalList.push(defaultProposal);
			});
			return proposalList;
		},

		_filterCreatedDate(date) {
			let momentDate = moment(date);
			let year = momentDate.format('YYYY');
			let month = momentDate.format('MM');
			let day = momentDate.format('DD');
			return `${year} 年 ${month} 月 ${day}日に提案`;
		},

		_renameItem(title, date) {
			if (title && date) {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');
				return `${year}年${month}月${day}日: ${title}`;
			} else {
				return title;
			}
		},

		/**
		 * Filter saved proposal items
		 * @param {Array} proposals Proposals Array from database
		 * @returns Saved proposal items
		 */
		_filterSavedItem(proposals) {
			let savedProposal = proposals.filter((item) => item['is_save'] === SAVED_NUMBER);
			return savedProposal;
		},

		_checkProposalStatus(status) {
			if (status === CommonConst.SCREEN_STATUS.MENU_OPEN) {
				this.showOptionMenu = true;
			} else if (status === CommonConst.SCREEN_STATUS.FINISH) {
				this.showOptionMenu = false;
			} else if (status === '') {
				this.showAnnoucement = true;
				this.showItemList = false;
			} else if (status === CommonConst.SCREEN_STATUS.REVISION) {
				this.showOptionMenu = false;
			} else if (status === CommonConst.SCREEN_STATUS.SEND) {
				this.showAnnoucement = false;
				this.showItemList = true;
			}
		},

		_checkScreenMode(screenMode) {
			if (screenMode == CommonConst.PROPOSAL_MODE_NUMB) {
				this.screenName = CommonConst.PROPOSAL_SCREEN_NAME;
				this.showFullOptionMenu = true;
			} else if (screenMode == CommonConst.REVISION_MODE_NUMB) {
				this.screenName = CommonConst.REVISION_SCREEN_NAME;
				this.showFullOptionMenu = false;
			}
		},
		/**
		 * Trigger Send mail
		 */
		async _sendMail(mailContents) {
			let responseSendMail = await Mail.sendMail(mailContents);
			if (responseSendMail.status !== 200) {
				throw 'Send mail failed!';
			}
			console.log('%c Send mail successfully!', 'color: green');
		},
	},
	async created() {
		socketClient.listen('new_proposal_updated', async (data) => {
			if (data.status) {
				this.$store.commit('setIsAppProcessing', true);

				await this._getAllProposalData(this.projectId);
				if (data.newName) {
					this.$emit(
						'on-change-proposal-name',
						data.newName,
						CommonConst.SCREEN_ID.PROPOSAL
					);
				}

				this.$store.commit('setIsAppProcessing', false);
			}
		});

		socketClient.listen('open_readonly_option_menu', (data) => {
			if (data.isOpen) {
				this.showOptionMenu = true;
				this.selectedHour = data.hourSelected.toString();
				this.selectedMinute = data.minuteSelected.toString();
			}
		});

		socketClient.listen('new_data_transfer', (data) => {
			if (data.event_name === 'delete_proposal_question') {
				this.removeQuestion(data.content);
			}
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		await this._getAllProposalData(this.projectId);

		this.$store.commit('setIsAppProcessing', false);
	},
};
