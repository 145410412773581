<template>
	<div :class="containerInputClass">
		<div :class="inputTitleClass" v-if="maxLength">{{ letterLength }}</div>
		<input type="text" :value="value" @input="updateValue" :maxlength="maxLength" />
	</div>
</template>

<script>
export default {
	name: 'InputCustom',
	props: {
		value: String,
		maxLength: String,
		containerInputClass: String,
		inputTitleClass: String,
	},
	computed: {
		letterLength() {
			const valueLength = this.value ? this.value.length : 0;

			return `${valueLength}/${this.maxLength}`;
		},
	},
	methods: {
		updateValue(event) {
			this.$emit('input', event.target.value);
		},
	},
};
</script>
<style lang="scss" scoped>
.inputWrapper {
	&__MaxLength {
		color: #808080;
		font-size: 11px;
		text-align: right;
		@include mq(lg) {
			font-size: 12px;
		}
	}
	input {
		background-color: #f7f8f8;
		height: 40px;
		width: 100%;
		border-radius: 7px;
		padding: 10px 15px;
		outline: none;
		color: #808080;
	}
}

.inputTitleWrapper{
	&__MaxLength {
		color: #808080;
		font-size: 11px;
		text-align: right;
		@include mq(lg) {
			font-size: 12px;
		}
	}
	input {
		background-color: #f2f2f2;
		border-radius: 7px;
		width: 100%;
		height: 32px;
		outline: none;
		font-size: 12px;
		font-family: NotoSansJPMedium;
		padding: 10px 15px;
		color: #4d4d4d;

		@include mq(lg) {
				font-size: 14px;
				height: 43px;
		}
	}
}
</style>
