import ImageUpload from '@/components/ImageUploadWithProgressBar/ImageUploadWithProgressBar.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import Modal from '@/components/Modal/Modal.vue';
import SkeletonLoadingImage from '@/components/SkeletonLoadingImage/SkeletonLoadingImage.vue';

import InputCustom from '@/components/InputCustom/InputCustom.vue';
import TextArea from '@/components/TextArea/TextArea.vue';

import CommonConst from '../../constants/CommonConst';

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export default {
	name: 'CreateEditItem',
	components: {
		ImageUpload,
		NotificationModal,
		Modal,
		SkeletonLoadingImage,
		InputCustom,
		TextArea,
	},
	props: {
		itemData: {
			type: Object,
		},
		isOpen: {
			type: Boolean,
			default: false,
		},
		isSelect: {
			type: Boolean,
			default: false,
		},
		isCheck: {
			type: Boolean,
			default: false,
		},
		uploadImageKey: {
			type: String,
		},
		deleteAble: {
			type: Boolean,
		},
		isDownloadAvailable: {
			type: Boolean,
			default: true,
		}
	},
	data() {
		return {
			newImageUploaded: false,
			isEdit: false,
			isCreate: false,
			isRead: false,
			isDownload: false,
			title: '',
			comment: '',
			itemId: undefined,
			imageKey: undefined,
			id: undefined,
			errorContents: '',

			pdfFileIcon: CommonConst.PDF_FILE_ICON,

			uploadFileParams: {
				role: 'proposal',
				proposal_id: parseInt(this.$route.params.projectId),
			},

			pdfPreviewModal: 'create-edit-preview-pdf-modal',
			pdfPreviewer: {
				url: '',
			},
		};
	},
	computed: {
		disabledBtn() {
			if (!this.comment || !this.title) {
				return true;
			} else return false;
		},
		isPdf() {
			return this.itemData && this.itemData.fileType === 'pdf';
		},
	},
	watch: {
		uploadImageKey: {
			handler() {
				console.log('Get Image Key', this.uploadImageKey);
				this.imageKey = this.uploadImageKey;
			},
		},
		itemData: {
			handler() {
				console.log('On Select', this.itemData);
				this.title = this.itemData['title'];
				this.comment = this.itemData['comment'];
				this.imageKey = this.itemData['imageKey'];
				this.id = this.itemData['id'];
			},
		},
		isOpen: {
			handler() {
				this._revertData();
				this.isCreate = true;
			},
		},
		isSelect: {
			handler() {
				this.isRead = true;
			},
		},
		isCheck: {
			handler() {
				this.isDownload = true;
			},
		},
	},
	updated() {
		let modalComment = document.getElementById('modal-comment')
		if (modalComment != null) {
			let stringList = this.comment.split(" ");
			stringList = stringList.map((string) => {
				let stringBr = string.split("\n");
				if(stringBr.length > 1) {
					for (let i = 0; i < stringBr.length; i++) {
						if (isValidHttpUrl(stringBr[i])) {
							let brHeader = stringBr[i - 1] ? "" : "\n";
							let brFooter = stringBr[i + 1] ? "" : "\n";
							stringBr[i] = brHeader + "<a href='" + stringBr[i] + "' target='_blank'>" + stringBr[i] + "</a>" + brFooter;
						}
					}
					return stringBr.join("\n");
				}
				else {
					if (isValidHttpUrl(string)) {
						return "\n<a href='" + string + "' target='_blank'>" + string + "</a>\n";
					}
					else {
						return string
					}
				}
			})
			modalComment.innerHTML = stringList.join(' ');
		}
	},
	methods: {
		onDownloadPdfFileFromChild() { 
			this.$emit('on-download-pdf-file', this.pdfPreviewer);
		},
		onInputRemark({ comment }) {
			this.comment = comment;
		},
		onUploadImage(data) {
			this.newImageUploaded = true;
			this.$emit('on-upload', data);
		},

		onPreview() {
			if (this.isPdf) {
				this.pdfPreviewer.url = this.itemData.imageUrl;
				this.$bvModal.show(`${this.pdfPreviewModal}`);
			}
		},

		onRegister() {
			let registerContent = {
				title: this.title,
				comment: this.comment,
				imageKey: this.imageKey,
			};
			console.log(registerContent);
			this.$emit('on-register', registerContent);
			this._revertData();
			this.$bvModal.hide('create-edit-modal');
		},

		onEdit() {
			this.isRead = false;
			this.isEdit = true;
			this.$emit('on-edit');
		},

		onClose() {
			this.$bvModal.hide('create-edit-modal');
			this._revertData();
		},

		onUpdate() {
			let updateContent = {
				title: this.title,
				comment: this.comment,
				imageKey: this.imageKey,
				id: this.id,
			};
			this.newImageUploaded = true;
			this.$emit('on-update', updateContent);
			this.$bvModal.hide('create-edit-modal');
			this._revertData();
		},

		onDownload() {
			console.log('clicked');
		},

		onRemoveImageData() {
			this.imageKey = '';
			this.newImageUploaded = false;
		},

		_revertData() {
			this.title = '';
			this.comment = '';
			this.imageKey = '';
			this.isEdit = false;
			this.isCreate = false;
			this.isRead = false;
			this.isDownload = false;
			this.newImageUploaded = false;
		},
	},
};