const DELIVERY_DESCRIPTION = '提案一覧の中から\n決定して納品';
const REVISION_DESCRIPTION = '納期を変えずに\n修正を依頼';
const RE_PROPOSAL_DESCRIPTION = '納期延長をして\n追加費用で再提案を依頼';

export default {
	name: 'OptionMenu',
	props: {
		showFullOptionMenu: {
			type: Boolean,
		},
		isSelective: {
			type: Boolean,
		},
		selectedHour: {
			type: String,
		},
		selectedMinute: {
			type: String,
		},
	},
	data() {
		return {
			deliveryDescription: DELIVERY_DESCRIPTION,
			revisionDescription: REVISION_DESCRIPTION,
			reProposalDescription: RE_PROPOSAL_DESCRIPTION,
		};
	},
	methods: {
		onDeliveryClick() {
			this.$emit('on-delivery-click');
		},
		onRevisionClick() {
			this.$emit('on-revision-click');
		},
		onReProposalClick() {
			this.$emit('on-re-proposal-click');
		},
	},
};
